import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { SupportSection } from '../components/sections';

const SupportPage: React.FC = () => (
  <Layout hideHeader>
    <SEO title="Support" />
    <SupportSection />
  </Layout>
);

export default SupportPage;
